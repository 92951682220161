import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: localStorage.getItem("attendee")
    ? JSON.parse(localStorage.getItem("attendee"))
    : {},
  loading: false,
  error: null,
  authToken: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginLoading(state) {
      state.loading = true;
      state.error = null;
    },
    loginError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setLoginList(state, action) {
      localStorage.setItem("attendee", JSON.stringify(action.payload));
      state.loading = false;
      state.data = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const {
  loginLoading,
  loginError,
  loginSuccess,
  setLoginList,
  setAuthToken,
} = loginSlice.actions;

export default loginSlice.reducer;

import { loaderIcon } from "../../assets";
import "./loader.css";

const Loader = ({ height = "30px", style }) => {
  return (
    <img
      className="loader"
      src={loaderIcon}
      alt="loaderIcon"
      height={height}
      style={style}
    />
  );
};

export default Loader;

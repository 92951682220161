import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../components/PageLoader";

export const Router = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const authToken = localStorage.getItem("authToken");
  const verifyToken = localStorage.getItem("verifyToken");

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
    // setIsLoggedIn(authToken && verifyToken ? true : false);
    setIsLoggedIn(authToken ? true : false);
  }, [location, authToken, verifyToken]);

  if (
    !isLoggedIn &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/register" &&
    window.location.pathname !== "/verification"
  ) {
    return <Navigate to="/login" />;
  } else if (
    isLoggedIn &&
    (window.location.pathname === "/login" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/register" ||
      window.location.pathname === "/verification")
  ) {
    return <Navigate to="/quiz" />;
  }

  const Login = lazy(() => import("../pages/Auth/Login"));
  const Registration = lazy(() => import("../pages/Auth/Registration"));
  const Verification = lazy(() => import("../pages/Auth/Verification"));
  const Quiz = lazy(() => import("../pages/Quiz/index"));

  return (
    <Routes>
      {!isLoggedIn && (
        <>
          <Route
            path="/login"
            element={
              <Suspense fallback={<PageLoader />}>
                <Login />
              </Suspense>
            }
          />

          <Route path="/" element={<Navigate to="/login" />} />

          <Route
            path="/register"
            element={
              <Suspense fallback={<PageLoader />}>
                <Registration />
              </Suspense>
            }
          />

          <Route
            path="/verification"
            element={
              <Suspense fallback={<PageLoader />}>
                <Verification />
              </Suspense>
            }
          />
        </>
      )}

      {isLoggedIn && (
        <>
          <Route
            path="/quiz"
            element={
              <Suspense fallback={<PageLoader />}>
                <Quiz />
              </Suspense>
            }
          />
        </>
      )}

      <Route path="404" element={<>Not Found</>} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default Router;

import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/Login";
import registerReducer from "./slices/Register";

const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
